import { clubSlotActions } from '../actions/definition';
import { IClubSlotState } from '../interfaces/clubSlot';
import { ClubSlotActionTypes } from '../interfaces/typesClubSlot';

const defaultState: IClubSlotState = {
    isLoading: false,
    data: null,
    error: null,
};

const clubSlotReducer = (state = defaultState, action: ClubSlotActionTypes): IClubSlotState => {
    switch (action.type) {
        case clubSlotActions.CLUB_SLOT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case clubSlotActions.CLUB_SLOT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: state.error,
            };
        case clubSlotActions.CLUB_SLOT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case clubSlotActions.CLUB_SLOT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case clubSlotActions.CLUB_SLOT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default clubSlotReducer;
