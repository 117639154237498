/* eslint-disable */
import { Auth } from 'aws-amplify'

async function confirmNewPassword(email: string, code: string, newPassword: string) {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
}

async function confirmRegister(email: string, code: string) {
    const data = await Auth.confirmSignUp(email, code, {
        forceAliasCreation: true,
    })
    return data
}

async function forgotPassword(email: string) {
    await Auth.forgotPassword(email)
}

async function isLoggedIn() {
    await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
}

async function signOut() {
    await Auth.signOut()
}

export default {
    signOut,
    confirmRegister,
    forgotPassword,
    confirmNewPassword,
    isLoggedIn,
}
