/**
 * Model actions define
 */
 export const contactActions = {
    CONTACT_IS_LOADING: 'CONTACT_IS_LOADING',
    CONTACT_ERROR: 'CONTACT_ERROR',
    CONTACT_RESET: 'CONTACT_RESET',
    CONTACT_NEW: 'CONTACT_NEW',
    CONTACT: 'CONTACT',
}

