import { teamActions } from '../actions/definition';
import { ITeamState } from '../interfaces/team';
import { TeamActionTypes } from '../interfaces/typesTeam';

const defaultState: ITeamState = {
    isLoading: false,
    data: null,
    error: null,
};

const teamReducer = (state = defaultState, action: TeamActionTypes): ITeamState => {
    switch (action.type) {
        case teamActions.TEAM_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case teamActions.TEAM_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case teamActions.TEAM_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case teamActions.TEAM_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case teamActions.TEAM:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default teamReducer;
