import { defyActions } from '../actions/definition';
import { IDefyState } from '../interfaces/defy';
import { DefyActionTypes } from '../interfaces/typesDefy';

const defaultState: IDefyState = {
    isLoading: false,
    data: null,
    error: null,
};

const defyReducer = (state = defaultState, action: DefyActionTypes): IDefyState => {
    switch (action.type) {
        case defyActions.DEFY_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case defyActions.DEFY_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case defyActions.DEFY_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case defyActions.DEFY_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case defyActions.DEFY:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default defyReducer;
