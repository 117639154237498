import { rankingActions } from '../actions/definition';
import { IRankingState } from '../interfaces/ranking';
import { RankingActionTypes } from '../interfaces/typesRanking';

const defaultState: IRankingState = {
    isLoading: false,
    data: null,
    error: null,
};

const defyReducer = (state = defaultState, action: RankingActionTypes): IRankingState => {
    switch (action.type) {
        case rankingActions.RANKING_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case rankingActions.RANKING_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case rankingActions.RANKING_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case rankingActions.RANKING_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case rankingActions.RANKING:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default defyReducer;
