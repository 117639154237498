import { listCommuneActions } from '../actions/definition';
import { IListCommuneState } from '../interfaces/commune';
import { ListCommuneActionTypes } from '../interfaces/typesListCommunes';

const defaultState: IListCommuneState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listCommuneReducer = (state = defaultState, action: ListCommuneActionTypes): IListCommuneState => {
    switch (action.type) {
        case listCommuneActions.LIST_COMMUNE_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listCommuneActions.LIST_COMMUNE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listCommuneActions.LIST_COMMUNE:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listCommuneActions.LIST_COMMUNE_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listCommuneActions.LIST_COMMUNE_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listCommuneActions.LIST_COMMUNE_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listCommuneReducer;
