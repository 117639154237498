const ConfigAuth = {
  authProviderConfig: {
    auth_provider: process.env.REACT_APP_AUTH_PROVIDER,
  },
  api: {
    URL: process.env.REACT_APP_APIURL,
  },
  apiGateway: {
    NAME: process.env.REACT_APP_API_GATEWAY_NAME,
    REGION: process.env.REACT_APP_API_GATEWAY_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
  },
  apiGateway2: {
    NAME: process.env.REACT_APP_API_GATEWAY_NAME2,
    REGION: process.env.REACT_APP_API_GATEWAY_REGION2,
    URL: process.env.REACT_APP_API_GATEWAY_URL2,
  },
  cognitoConfig: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
};

export default ConfigAuth;
