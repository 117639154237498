import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AmplifyConfig from './config/provider/aws/amplify.config';
import { UserProvider } from './context/auth-context';
import configureStore from './store';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

import * as Sentry from '@sentry/browser';

import './index.css';

import App from './App';
import ScrollToTop from './common/components/scroll/ScrolloTo';

Sentry.init({ dsn: 'https://b548fdb851bd4cc29582332adca3f7a8@glitchtip.symlab.io/2' });

const store = configureStore();

Amplify.configure(AmplifyConfig);
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
}

ReactDOM.render(
    <Provider store={store}>
        <UserProvider>
            <BrowserRouter>
                <ScrollToTop />
                <App />
            </BrowserRouter>
        </UserProvider>
    </Provider>,
    document.getElementById('root'),
);
