import { reservationsActions } from '../actions/definition';
import { IReservationsState } from '../interfaces/field';
import { ReservationsActionTypes } from '../interfaces/typesReservations';

const defaultState: IReservationsState = {
    isLoading: false,
    data: null,
    error: null,
};

const reservationsReducer = (state = defaultState, action: ReservationsActionTypes): IReservationsState => {
    switch (action.type) {
        case reservationsActions.RESERVATIONS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case reservationsActions.RESERVATIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case reservationsActions.RESERVATIONS_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case reservationsActions.RESERVATIONS_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case reservationsActions.RESERVATIONS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default reservationsReducer;
