import { profileActions } from '../actions/definition';
import { IProfileState } from '../interfaces/profile';
import { ProfileActionTypes } from '../interfaces/typesProfile';

const defaultState: IProfileState = {
    isLoading: false,
    data: null,
    error: null,
};

const profileReducer = (state = defaultState, action: ProfileActionTypes): IProfileState => {
    switch (action.type) {
        case profileActions.PROFILE_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case profileActions.PROFILE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case profileActions.PROFILE_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case profileActions.PROFILE_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case profileActions.PROFILE:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default profileReducer;
