import { gameActions } from '../actions/definition';
import { IGameState } from '../interfaces/game';
import { GameActionTypes } from '../interfaces/typesGame';

const defaultState: IGameState = {
    isLoading: false,
    data: null,
    error: null,
};

const gameReducer = (state = defaultState, action: GameActionTypes): IGameState => {
    switch (action.type) {
        case gameActions.GAME_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case gameActions.GAME_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case gameActions.GAME_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case gameActions.GAME_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case gameActions.GAME:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default gameReducer;
