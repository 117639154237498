import { sportActions } from '../actions/definition';
import { ISportState } from '../interfaces/sport';
import { SportActionTypes } from '../interfaces/typesSport';

const defaultState: ISportState = {
    isLoading: false,
    data: null,
    error: null,
};

const sportReducer = (state = defaultState, action: SportActionTypes): ISportState => {
    switch (action.type) {
        case sportActions.SPORT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case sportActions.SPORT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case sportActions.SPORT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case sportActions.SPORT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case sportActions.SPORT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default sportReducer;
