import { listClubSelectActions } from '../actions/definition';
import { IListClubState } from '../interfaces/club';
import { ListClubActionTypes } from '../interfaces/typesListClub';

const defaultState: IListClubState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listClubSelectReducer = (state = defaultState, action: ListClubActionTypes): IListClubState => {
    switch (action.type) {
        case listClubSelectActions.LIST_CLUB_SELECT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listClubSelectActions.LIST_CLUB_SELECT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listClubSelectActions.LIST_CLUB_SELECT:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listClubSelectActions.LIST_CLUB_SELECT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listClubSelectActions.LIST_CLUB_SELECT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listClubSelectActions.LIST_CLUB_SELECT_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listClubSelectReducer;
