/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { IContext, IError, IUser, IUserReturn } from '../../../interfaces';
import { postModel } from '../../../services/api.service';
import { postModel as postModel2 } from '../../../services/api2.service';

import * as Sentry from '@sentry/browser';

import AuthClient from './index';

export const LoginApi = async (email: string): Promise<IUserReturn> => {
    const TAG = 'Step2::LoginApi::Api::login';
    const result = await postModel('users/login')({});

    // capturar en sentry el result de LoginApi
    Sentry.configureScope(function (scope) {
        scope.setLevel('log');
        scope.setUser({ email: email ? email : result.name + '-' + result.lastName });
        scope.setTag('fuction', TAG);
        scope.setTag('origin', 'API');
        scope.setTag('resource', 'users/login');
        scope.setTag('tag', 'log');
        scope.setTag('environment', 'QA');
        scope.setExtra(`API:users/login:result`, JSON.stringify(result));
        Sentry.captureMessage(`${TAG}::User:${email ? email : result.name + '-' + result.lastName}`, 'log');
        //Sentry.captureException(new Error(`${TAG}`));
    });

    return result;
};

export const userWelcome = async (email): Promise<IUserReturn> => {
    const result = await postModel2('users/welcome')({ email });
    return result;
};

export const useProvideAuthAws = (): IContext => {
    const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError | null>(null);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [user, setUser] = useState<IUser | null | undefined>();
    const [token, setToken] = useState<string | null | undefined>(null);
    const [sendedForgot, setSendedForgot] = useState<boolean>(false);
    const [emailForgot, setEmailForgot] = useState<string | null>(null);
    const [passwordOk, setPasswordOk] = useState<boolean>(false);
    const [userCognito, setUserCognito] = useState<IUser | null | undefined>();
    const [codeConfirmOk, setCodeConfirmOk] = useState<boolean>(false);

    const getToken = async (): Promise<void> => {
        try {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            setToken(token);
        } catch (error: any) {
            setError({
                statusCode: error.code,
                message: error.message,
                error: error.message,
            });
        }
    };

    const getUser = async (email: string): Promise<void> => {
        setError(null);
        try {
            const resultLogin = await LoginApi(email);
            if (resultLogin && resultLogin.data && resultLogin.statusCode === 'USER_LOGGED_IN') {
                const login = resultLogin && resultLogin.data ? resultLogin.data : null;
                if (
                    login &&
                    login.role &&
                    (login.role === 'ADMIN' || login.role === 'PLAYER' || login.role === 'CLUB_OWNER')
                ) {
                    const TAG = 'Step3::GetUser::Cognito::Auth::currentUserInfo';
                    const user = await Auth.currentUserInfo();
                    // capturar en sentry el result de LoginApi
                    Sentry.configureScope(function (scope) {
                        scope.setLevel('log');
                        scope.setUser({ email: email ? email : user.attributes['email'] });
                        scope.setTag('fuction', TAG);
                        scope.setTag('origin', 'COGNITO');
                        scope.setTag('resource', 'Auth.currentUserInfo');
                        scope.setTag('tag', 'log');
                        scope.setTag('environment', 'QA');
                        scope.setExtra(`COGNITO:Auth.currentUserInfo:result`, JSON.stringify(user));
                        Sentry.captureMessage(`${TAG}::User:${email ? email : user.attributes['email']}`, 'log');
                        //Sentry.captureException(new Error(`${TAG}`));
                    });

                    if (user != null) {
                        getToken();
                        const auth = {
                            email: login.email ? login.email : user.attributes['email'] ? user.attributes['email'] : '',
                            firstName: login.name
                                ? login.name
                                : user.attributes['given_name']
                                ? user.attributes['given_name']
                                : '',
                            lastName: login.lastName
                                ? login.lastName
                                : user.attributes['family_name']
                                ? user.attributes['family_name']
                                : '',
                            fullName: user.attributes['name'] ? user.attributes['name'] : '',
                            photoURL: login.photo ? login.photo : '#',
                            uid: user.attributes['sub'] ? user.attributes['sub'] : '',
                            role: login.role,
                            phone: login.phone,
                            userId: login.id,
                        };
                        if (login.firstLogin) {
                            setIsNewUser(login.firstLogin);
                            setPasswordOk(false);
                        }
                        setUser(auth);
                        setLoggedIn(true);
                        setIsLoading(false);
                    }
                } else {
                    // capturar en sentry el error de LoginApi
                    const TAG = 'ERROR::statusCode:NOT:USER_LOGGED_IN::LoginApi';
                    Sentry.configureScope(function (scope) {
                        scope.setLevel('error');
                        scope.setTag('fuction', TAG);
                        scope.setTag('origin', 'API');
                        scope.setTag('resource', 'users/login');
                        scope.setTag('tag', 'error');
                        scope.setTag('environment', 'QA');
                        scope.setExtra(`${TAG}::`, JSON.stringify(error));
                        Sentry.captureException(new Error(`${TAG}::User:${email}`));
                    });
                    setError({
                        statusCode: 'UNAUTHORIZED_USER',
                        message: 'Usuario No autorizado',
                        error: 'unauthorized user',
                    });
                    setIsLoading(false);
                    logout();
                }
            } else {
                setError({
                    statusCode: resultLogin.statusCode,
                    message: resultLogin.message,
                    error: resultLogin.error!,
                });
                // capturar en sentry el error de LoginApi
                const TAG = 'ERROR::UNAUTHORIZED_USER::LoginApi';
                Sentry.configureScope(function (scope) {
                    scope.setLevel('error');
                    scope.setTag('fuction', TAG);
                    scope.setTag('origin', 'API');
                    scope.setTag('resource', 'users/login');
                    scope.setTag('tag', 'error');
                    scope.setTag('environment', 'QA');
                    scope.setExtra(`${TAG}::`, JSON.stringify(error));
                    Sentry.captureException(new Error(`${TAG}::User:${email}`));
                });
                setIsLoading(false);
                logout();
            }
        } catch (error: any) {
            // capturar en sentry el error de LoginApi
            const TAG = 'ERROR::CATCH::LoginApi';
            Sentry.configureScope(function (scope) {
                scope.setLevel('error');
                scope.setTag('fuction', TAG);
                scope.setTag('origin', 'API');
                scope.setTag('resource', 'users/login');
                scope.setTag('tag', 'error');
                scope.setTag('environment', 'QA');
                scope.setExtra(`${TAG}::`, JSON.stringify(error));
                Sentry.captureException(new Error(`${TAG}::User:${email}`));
            });
            setError({
                statusCode: error.code ? error.code : error.__type,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
            logout();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loginEmail = async (email: string, password: string): Promise<any> => {
        setError(null);
        if (email && password) {
            try {
                const TAG = 'Step1::LoginEmail::Cognito::Auth::signIn';
                setIsLoading(true);
                const user = await Auth.signIn(email.toLowerCase().trim(), password);
                // capturar en sentry el user de cognito
                Sentry.configureScope(function (scope) {
                    scope.setLevel('log');
                    scope.setUser({ email: email });
                    scope.setTag('fuction', TAG);
                    scope.setTag('origin', 'COGNITO');
                    scope.setTag('resource', 'Auth.signIn');
                    scope.setTag('tag', 'log');
                    scope.setTag('environment', 'QA');
                    scope.setExtra(`COGNITO:Auth.signIn:result`, JSON.stringify(user));
                    Sentry.captureMessage(`${TAG}::User:${email}`, 'log');
                    //Sentry.captureException(new Error(`${TAG}`));
                });
                setUserCognito(user);
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    console.log('MFA code challenge');
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    console.log('New password required');
                } else if (user.challengeName === 'MFA_SETUP') {
                    console.log('MFA_SETUP');
                    Auth.setupTOTP(user);
                } else {
                    if (user.challengeName !== undefined) {
                        console.log('challengeName:', user.challengeName);
                    }
                }
                await getUser(email);
                return user;
            } catch (error: any) {
                // capturar en sentry el error de LoginApi
                const TAG = 'ERROR::LoginEmail';
                Sentry.configureScope(function (scope) {
                    scope.setLevel('error');
                    scope.setTag('fuction', TAG);
                    scope.setTag('origin', 'API');
                    scope.setTag('resource', 'users/login');
                    scope.setTag('tag', 'error');
                    scope.setTag('environment', 'QA');
                    scope.setExtra(`${TAG}::`, JSON.stringify(error));
                    Sentry.captureException(new Error(`${TAG}::User:${email}`));
                });
                setIsLoading(false);
                if (error && error.code && error.code === 'UserNotConfirmedException') {
                    try {
                        await Auth.resendSignUp(email);
                        //registro/codigo
                    } catch (err) {
                        console.log('error resending code: ', err);
                    }
                }
                setError({
                    statusCode: error.code,
                    message: error.message || 'Error en loginEmail',
                    error: error.message || 'Error en loginEmail',
                    origin: 'login',
                });
            }
        } else {
            setError({
                statusCode: 'EMAIL_PASSOWRD_REQUIRED',
                message: 'EMAIL_PASSOWRD_REQUIRED',
                error: 'EMAIL_PASSOWRD_REQUIRED',
                origin: 'login',
            });
        }
    };

    const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            await Auth.changePassword(userCognito, oldPassword, newPassword);
            setSendedForgot(false);
            setPasswordOk(true);
            setIsNewUser(false);
            setIsLoading(false);
        } catch (error: any) {
            setError({
                statusCode: error.code ? error.code : error.__type,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
        }
    };

    const changeNotPassword = async (): Promise<void> => {
        try {
            setIsNewUser(false);
        } catch (error: any) {
            setError({
                statusCode: error.code ? error.code : error.__type,
                message: error.message,
                error: error.message,
            });
            setLoggedIn(false);
        }
    };

    const logout = async (): Promise<void> => {
        try {
            await AuthClient.signOut();
            setLoggedIn(false);
        } catch (error: any) {
            setError({
                statusCode: error.code ? error.code : error.__type,
                message: error.message,
                error: error.message,
            });
            setLoggedIn(false);
        }
    };

    const confirmCode = async (email: string, code: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            await AuthClient.confirmRegister(email, code);
            userWelcome(email);
            setIsLoading(false);
            setLoggedIn(false);
            setCodeConfirmOk(true);
        } catch (error: any) {
            if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                setError({
                    statusCode: 'NotAuthorizedExceptionConfirmed',
                    message: error.message,
                    error: error.message,
                });
            } else {
                setError({
                    statusCode: error.code ? error.code : error.__type,
                    message: error.message,
                    error: error.message,
                });
            }
            setIsLoading(false);
            setLoggedIn(false);
            setCodeConfirmOk(false);
            logout();
        }
    };

    const forgotCode = async (email: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            if (email) {
                await AuthClient.forgotPassword(email);
                setIsLoading(false);
                setLoggedIn(false);
                setEmailForgot(email);
                setSendedForgot(true);
            } else {
                setError({
                    statusCode: 'fieldEmpty',
                    message: 'Se requiero correo',
                    error: 'Se requiero correo',
                });
            }
        } catch (error: any) {
            if (error.message === 'User password cannot be reset in the current state.') {
                setError({
                    statusCode: 'NotAuthorizedExceptionForgot',
                    message: error.message,
                    error: error.message,
                });
            } else {
                setError({
                    statusCode: error.code ? error.code : error.__type,
                    message: error.message ? error.message : 'Error en forgotPassword',
                    error: error.message ? error.message : 'Error en forgotPassword',
                });
            }
            setSendedForgot(false);
            setIsLoading(false);
            setLoggedIn(false);
        }
    };

    const forgotConfirm = async (email: string, code: string, password: string): Promise<void> => {
        setIsLoading(true);
        setError(null);
        try {
            if (email && code && password) {
                await AuthClient.confirmNewPassword(email, code, password);
                setEmailForgot(null);
                setSendedForgot(false);
                setPasswordOk(true);
                setIsLoading(false);
                setLoggedIn(false);
            } else {
                setError({
                    statusCode: 'fieldEmpty',
                    message: 'Se requiero correo, code y clave',
                    error: 'Se requiero correo, code y clave',
                });
            }
        } catch (error: any) {
            setError({
                statusCode: error.code ? error.code : error.__type,
                message: error.message,
                error: error.message,
            });
            setIsLoading(false);
            setLoggedIn(false);
            logout();
        }
    };

    const clearStateChangePassword = async (): Promise<void> => {
        setSendedForgot(false);
        setPasswordOk(false);
        setIsNewUser(false);
        setIsLoading(false);
        setCodeConfirmOk(false);
    };

    const clearErrorAuth = async (): Promise<void> => {
        setError(null);
    };

    useEffect(() => {
        const ac = new AbortController();
        async function setUp() {
            if (!isLoggedIn && error === null) {
                try {
                    await AuthClient.isLoggedIn();
                    setLoggedIn(true);
                    await getToken();
                    const user = await Auth.currentUserInfo();
                    const email = user.attributes['email'] ? user.attributes['email'] : 'NotCurrentUserInfo';
                    await getUser(email);
                } catch (e) {
                    setLoggedIn(false);
                    setIsLoading(false);
                }
            } else {
                //console.log('entre en useEffect ELSE')
            }
        }
        setUp();
        //When any of the Promises are aborted, Promise will reject with AbortError
        return () => ac.abort();
    }, [isLoggedIn]);

    /*useEffect(() => {
        console.log('***********************************', { error });
    }, [error]);*/

    return {
        user,
        isLoggedIn,
        loading,
        error,
        token,
        isNewUser,
        sendedForgot,
        emailForgot,
        passwordOk,
        codeConfirmOk,
        loginEmail,
        logout,
        confirmCode,
        changePassword,
        forgotConfirm,
        forgotCode,
        changeNotPassword,
        clearStateChangePassword,
        clearErrorAuth,
    };
};
