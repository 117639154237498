import { listRegionActions } from '../actions/definition';
import { IListRegionState } from '../interfaces/region';
import { ListRegionActionTypes } from '../interfaces/typesListRegions';

const defaultState: IListRegionState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listRegionReducer = (state = defaultState, action: ListRegionActionTypes): IListRegionState => {
    switch (action.type) {
        case listRegionActions.LIST_REGION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listRegionActions.LIST_REGION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listRegionActions.LIST_REGION:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listRegionActions.LIST_REGION_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listRegionActions.LIST_REGION_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listRegionActions.LIST_REGION_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listRegionReducer;
