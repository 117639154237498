import React from 'react';

const Loading: React.FC = () => {
    return (
        <div>
            <div className=" flex justify-center items-center mt-8 py-8 pt-28  min-h-layout">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
            </div>
            <div className=" flex justify-center items-center"> Por favor Espere...</div>
        </div>
    );
};

export default Loading;
