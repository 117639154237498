import { listSportActions } from '../actions/definition';
import { IListSportState } from '../interfaces/sport';
import { ListSportActionTypes } from '../interfaces/typesListSports';

const defaultState: IListSportState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listSportReducer = (state = defaultState, action: ListSportActionTypes): IListSportState => {
    switch (action.type) {
        case listSportActions.LIST_SPORT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listSportActions.LIST_SPORT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listSportActions.LIST_SPORT:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listSportActions.LIST_SPORT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listSportActions.LIST_SPORT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listSportActions.LIST_SPORT_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listSportReducer;
