import { priceActions } from '../actions/definition';
import { IPriceState } from '../interfaces/field';
import { PriceActionTypes } from '../interfaces/typesPrice';

const defaultState: IPriceState = {
    isLoading: false,
    data: null,
    error: null,
};

const priceReducer = (state = defaultState, action: PriceActionTypes): IPriceState => {
    switch (action.type) {
        case priceActions.PRICES_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            };
        case priceActions.PRICES_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.data,
            };
        case priceActions.PRICES_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            };
        case priceActions.PRICES_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        case priceActions.PRICES:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            };
        default:
            return state;
    }
};

export default priceReducer;
