import contactReducer from '../modules/contact/reducers';
import clubReducer from '../modules/sport/reducers/club';
import listClubReducer from '../modules/sport/reducers/listClub';
import { combineReducers } from 'redux';
import listFormatReducer from '../modules/sport/reducers/listFormat';
import gameReducer from '../modules/sport/reducers/game';
import listGameReducer from '../modules/sport/reducers/listGames';
import listFieldReducer from '../modules/sport/reducers/listField';
import clubSlotReducer from '../modules/sport/reducers/clubSlot';
import reservationsReducer from '../modules/sport/reducers/reservations';
import priceReducer from '../modules/sport/reducers/price';
import sportReducer from '../modules/sport/reducers/sport';
import listSportReducer from '../modules/sport/reducers/listSport';
import listRegionReducer from '../modules/sport/reducers/listRegions';
import listCommuneReducer from '../modules/sport/reducers/listCommunes';
import listClubSelectReducer from '../modules/sport/reducers/listClubSelect';
import profileReducer from '../modules/sport/reducers/profile';
import defyReducer from '../modules/sport/reducers/defy';
import listDefyReducer from '../modules/sport/reducers/listDefys';
import defyResponseReducer from '../modules/sport/reducers/defyResponse';
import teamReducer from '../modules/sport/reducers/team';
import rankingReducer from '../modules/sport/reducers/ranking';

const rootReducer = combineReducers({
    contacts: contactReducer,
    listClubs: listClubReducer,
    club: clubReducer,
    listFormats: listFormatReducer,
    listGames: listGameReducer,
    game: gameReducer,
    listFields: listFieldReducer,
    clubSlot: clubSlotReducer,
    reservations: reservationsReducer,
    priceState: priceReducer,
    listSport: listSportReducer,
    sport: sportReducer,
    listRegions: listRegionReducer,
    listCommunes: listCommuneReducer,
    listClubSelect: listClubSelectReducer,
    profile: profileReducer,
    listDefys: listDefyReducer,
    defy: defyReducer,
    defyResponse: defyResponseReducer,
    team: teamReducer,
    listRanking: rankingReducer,
});

export default rootReducer;
