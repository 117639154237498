/**
 * Model actions define for a list of clubs
 */

export const listClubActions = {
    LIST_CLUB_IS_LOADING: 'LIST_CLUB_IS_LOADING',
    LIST_CLUB_ERROR: 'LIST_CLUB_ERROR',
    LIST_CLUB_RESET: 'LIST_CLUB_RESET',
    LIST_CLUB: 'LIST_CLUB',
    LIST_CLUB_LIST: 'LIST_CLUB_LIST',
    LIST_CLUB_GET: 'LIST_CLUB_GET',
};

export const listClubSelectActions = {
    LIST_CLUB_SELECT_IS_LOADING: 'LIST_CLUB_SELECT_IS_LOADING',
    LIST_CLUB_SELECT_ERROR: 'LIST_CLUB_SELECT_ERROR',
    LIST_CLUB_SELECT_RESET: 'LIST_CLUB_SELECT_RESET',
    LIST_CLUB_SELECT: 'LIST_CLUB_SELECT',
    LIST_CLUB_SELECT_LIST: 'LIST_CLUB_SELECT_LIST',
    LIST_CLUB_SELECT_GET: 'LIST_CLUB_SELECT_GET',
};

export const listSportActions = {
    LIST_SPORT_IS_LOADING: 'LIST_SPORT_IS_LOADING',
    LIST_SPORT_ERROR: 'LIST_SPORT_ERROR',
    LIST_SPORT_RESET: 'LIST_SPORT_RESET',
    LIST_SPORT: 'LIST_SPORT',
    LIST_SPORT_LIST: 'LIST_SPORT_LIST',
    LIST_SPORT_GET: 'LIST_SPORT_GET',
};

/**
 * Model actions define for a clubs
 */

export const clubActions = {
    CLUB_IS_LOADING: 'CLUB_IS_LOADING',
    CLUB_ERROR: 'CLUB_ERROR',
    CLUB_RESET: 'CLUB_RESET',
    CLUB: 'CLUB',
    CLUB_LIST: 'CLUB_LIST',
    CLUB_GET: 'CLUB_GET',
};

/**
 * Model actions define for a clubs
 */

export const clubSlotActions = {
    CLUB_SLOT_IS_LOADING: 'CLUB_SLOT_IS_LOADING',
    CLUB_SLOT_ERROR: 'CLUB_SLOT_ERROR',
    CLUB_SLOT_RESET: 'CLUB_SLOT_RESET',
    CLUB_SLOT: 'CLUB_SLOT',
    CLUB_SLOT_LIST: 'CLUB_SLOT_LIST',
    CLUB_SLOT_GET: 'CLUB_SLOT_GET',
};

/**
 * Model actions define for a list of format
 */

export const listFormatActions = {
    LIST_FORMAT_IS_LOADING: 'LIST_FORMAT_IS_LOADING',
    LIST_FORMAT_ERROR: 'LIST_FORMAT_ERROR',
    LIST_FORMAT_RESET: 'LIST_FORMAT_RESET',
    LIST_FORMAT: 'LIST_FORMAT',
    LIST_FORMAT_LIST: 'LIST_FORMAT_LIST',
    LIST_FORMAT_GET: 'LIST_FORMAT_GET',
};

/**
 * Model actions define for a list of games
 */

export const listGameActions = {
    LIST_GAME_IS_LOADING: 'LIST_GAME_IS_LOADING',
    LIST_GAME_ERROR: 'LIST_GAME_ERROR',
    LIST_GAME_RESET: 'LIST_GAME_RESET',
    LIST_GAME: 'LIST_GAME',
    LIST_GAME_LIST: 'LIST_GAME_LIST',
    LIST_GAME_GET: 'LIST_GAME_GET',
};

export const listDefyActions = {
    LIST_DEFY_IS_LOADING: 'LIST_DEFY_IS_LOADING',
    LIST_DEFY_ERROR: 'LIST_DEFY_ERROR',
    LIST_DEFY_RESET: 'LIST_DEFY_RESET',
    LIST_DEFY: 'LIST_DEFY',
    LIST_DEFY_LIST: 'LIST_DEFY_LIST',
    LIST_DEFY_GET: 'LIST_DEFY_GET',
};

/**
 * Model actions define for a games
 */

export const gameActions = {
    GAME_IS_LOADING: 'GAME_IS_LOADING',
    GAME_ERROR: 'GAME_ERROR',
    GAME_RESET: 'GAME_RESET',
    GAME: 'GAME',
    GAME_LIST: 'GAME_LIST',
    GAME_GET: 'GAME_GET',
};

export const defyActions = {
    DEFY_IS_LOADING: 'DEFY_IS_LOADING',
    DEFY_ERROR: 'DEFY_ERROR',
    DEFY_RESET: 'DEFY_RESET',
    DEFY: 'DEFY',
    DEFY_LIST: 'DEFY_LIST',
    DEFY_GET: 'DEFY_GET',
};

export const defyResponseActions = {
    DEFY_IS_LOADING: 'DEFY_IS_LOADING',
    DEFY_ERROR: 'DEFY_ERROR',
    DEFY_RESET: 'DEFY_RESET',
    DEFY: 'DEFY',
    DEFY_LIST: 'DEFY_LIST',
    DEFY_GET: 'DEFY_GET',
};

/**
 * Model actions define for a list of field
 */

export const listFieldActions = {
    LIST_FIELD_IS_LOADING: 'LIST_FIELD_IS_LOADING',
    LIST_FIELD_ERROR: 'LIST_FIELD_ERROR',
    LIST_FIELD_RESET: 'LIST_FIELD_RESET',
    LIST_FIELD: 'LIST_FIELD',
    LIST_FIELD_LIST: 'LIST_FIELD_LIST',
    LIST_FIELD_GET: 'LIST_FIELD_GET',
};

/**
 * Model actions define for a prices
 */

export const priceActions = {
    PRICES_IS_LOADING: 'PRICES_IS_LOADING',
    PRICES_ERROR: 'PRICES_ERROR',
    PRICES_RESET: 'PRICES_RESET',
    PRICES: 'PRICES',
    PRICES_LIST: 'PRICES_LIST',
    PRICES_GET: 'PRICES_GET',
};

/**
 * Model actions define for a reservations
 */

export const reservationsActions = {
    RESERVATIONS_IS_LOADING: 'RESERVATIONS_IS_LOADING',
    RESERVATIONS_ERROR: 'RESERVATIONS_ERROR',
    RESERVATIONS_RESET: 'RESERVATIONS_RESET',
    RESERVATIONS: 'RESERVATIONS',
    RESERVATIONS_LIST: 'RESERVATIONS_LIST',
    RESERVATIONS_GET: 'RESERVATIONS_GET',
};

/**
 * Model actions define for a sports
 */

export const sportActions = {
    SPORT_IS_LOADING: 'SPORT_IS_LOADING',
    SPORT_ERROR: 'SPORT_ERROR',
    SPORT_RESET: 'SPORT_RESET',
    SPORT: 'SPORT',
    SPORT_LIST: 'SPORT_LIST',
    SPORT_GET: 'SPORT_GET',
};

/**
 * Model actions define for a list of regions
 */

export const listRegionActions = {
    LIST_REGION_IS_LOADING: 'LIST_REGION_IS_LOADING',
    LIST_REGION_ERROR: 'LIST_REGION_ERROR',
    LIST_REGION_RESET: 'LIST_REGION_RESET',
    LIST_REGION: 'LIST_REGION',
    LIST_REGION_LIST: 'LIST_REGION_LIST',
    LIST_REGION_GET: 'LIST_REGION_GET',
};

/**
 * Model actions define for a list of commune
 */

export const listCommuneActions = {
    LIST_COMMUNE_IS_LOADING: 'LIST_COMMUNE_IS_LOADING',
    LIST_COMMUNE_ERROR: 'LIST_COMMUNE_ERROR',
    LIST_COMMUNE_RESET: 'LIST_COMMUNE_RESET',
    LIST_COMMUNE: 'LIST_COMMUNE',
    LIST_COMMUNE_LIST: 'LIST_COMMUNE_LIST',
    LIST_COMMUNE_GET: 'LIST_COMMUNE_GET',
};

/**
 * Model actions define for a PROFILEs
 */

export const profileActions = {
    PROFILE_IS_LOADING: 'PROFILE_IS_LOADING',
    PROFILE_ERROR: 'PROFILE_ERROR',
    PROFILE_RESET: 'PROFILE_RESET',
    PROFILE: 'PROFILE',
    PROFILE_LIST: 'PROFILE_LIST',
    PROFILE_GET: 'PROFILE_GET',
};

export const teamActions = {
    TEAM_IS_LOADING: 'TEAM_IS_LOADING',
    TEAM_ERROR: 'TEAM_ERROR',
    TEAM_RESET: 'TEAM_RESET',
    TEAM: 'TEAM',
    TEAM_LIST: 'TEAM_LIST',
    TEAM_GET: 'TEAM_GET',
};

export const rankingActions = {
    RANKING_IS_LOADING: 'RANKING_IS_LOADING',
    RANKING_ERROR: 'RANKING_ERROR',
    RANKING_RESET: 'RANKING_RESET',
    RANKING: 'RANKING',
    RANKING_LIST: 'RANKING_LIST',
    RANKING_GET: 'RANKING_GET',
};
