import React, { Suspense } from 'react';

import { Switch } from 'react-router-dom';

import { useAuth } from './context/auth-context';

import Loading from './common/loading/Loading';

const loadAuthenticatedApp = () => import('./routes/Authenticated');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnAuthenticatedApp = React.lazy(() => import('./routes/UnAuthenticated'));

function App(): React.ReactElement {
    const auth = useAuth();

    //console.log('aqui Apps:', auth);

    React.useEffect(() => {
        loadAuthenticatedApp();
    });

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {auth ? (
                    auth.loading ? (
                        <Loading />
                    ) : !auth.isLoggedIn ? (
                        <UnAuthenticatedApp />
                    ) : (
                        <AuthenticatedApp />
                    )
                ) : (
                    <Loading />
                )}
            </Switch>
        </Suspense>
    );
}

export default App;
