import { contactActions } from '../actions/definition'
import { IContactState } from '../interface'
import { ContactActionTypes } from '../interface/types'

const defaultState: IContactState = {
    isLoading: false,
    data: null,
    error: null,
}

const contactReducer = (state = defaultState, action: ContactActionTypes): IContactState => {
    switch (action.type) {
        case contactActions.CONTACT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
            }
        case contactActions.CONTACT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: action.error,
            }
        case contactActions.CONTACT_NEW:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            }
        case contactActions.CONTACT:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
            }
        case contactActions.CONTACT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
            }
        default:
            return state
    }
}

export default contactReducer
