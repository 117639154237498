import { Middleware } from 'redux'

const logger =
    () =>
    (next: (arg0: never) => never) =>
    (action: never): Middleware => {
        const result: never = next(action)
        return result
    }

export default logger
