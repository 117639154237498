import { listFormatActions } from '../actions/definition';
import { IListFormatState } from '../interfaces/format';
import { ListFormatActionTypes } from '../interfaces/typesListFormat';

const defaultState: IListFormatState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listFormatReducer = (state = defaultState, action: ListFormatActionTypes): IListFormatState => {
    switch (action.type) {
        case listFormatActions.LIST_FORMAT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listFormatActions.LIST_FORMAT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: state.error,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFormatActions.LIST_FORMAT:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFormatActions.LIST_FORMAT_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listFormatActions.LIST_FORMAT_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFormatActions.LIST_FORMAT_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listFormatReducer;
