import { listFieldActions } from '../actions/definition';
import { IListFieldState } from '../interfaces/field';
import { ListFieldActionTypes } from '../interfaces/typesListFields';

const defaultState: IListFieldState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listFieldReducer = (state = defaultState, action: ListFieldActionTypes): IListFieldState => {
    switch (action.type) {
        case listFieldActions.LIST_FIELD_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listFieldActions.LIST_FIELD_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFieldActions.LIST_FIELD:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFieldActions.LIST_FIELD_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listFieldActions.LIST_FIELD_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listFieldActions.LIST_FIELD_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listFieldReducer;
