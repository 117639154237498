import { listGameActions } from '../actions/definition';
import { IListGameState } from '../interfaces/game';
import { ListGameActionTypes } from '../interfaces/typesListGames';

const defaultState: IListGameState = {
    isLoading: false,
    data: null,
    error: null,
    links: null,
    meta: null,
};

const listGameReducer = (state = defaultState, action: ListGameActionTypes): IListGameState => {
    switch (action.type) {
        case listGameActions.LIST_GAME_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || null,
                error: null,
                links: null,
                meta: null,
            };
        case listGameActions.LIST_GAME_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || null,
                error: action.data,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listGameActions.LIST_GAME:
            return {
                ...state,
                isLoading: false,
                data: action.data || null,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listGameActions.LIST_GAME_RESET:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: null,
                links: null,
                meta: null,
            };
        case listGameActions.LIST_GAME_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        case listGameActions.LIST_GAME_LIST:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null,
                links: action.links || null,
                meta: action.meta || null,
            };
        default:
            return state;
    }
};

export default listGameReducer;
